.modalWrapper {
    width: 700px;
    height: 565px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: white;
    border-radius: 20px;
    font-family: Montserrat;
}

.background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: var(--modal-zindex);
    overflow-y: scroll;
}

.modalInner {
    padding: 56px 56px 56px 100px;
    box-sizing: border-box;
}

.graphContainer {
    display: flex;
    align-items: flex-start;
    gap: 150px;
}

.graph {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 80%;
    width: 100px;
    height: 450px;
    border-bottom: 3px solid var(--color-blue);
}

.graph2019 {
    background-image: url("../../assets/svg/innumbers_graph/2019.svg");
}

.graph2020 {
    background-image: url("../../assets/svg/innumbers_graph/2020.svg");
}

.graph2021 {
    background-image: url("../../assets/svg/innumbers_graph/2021.svg");
}

.graph2022 {
    background-image: url("../../assets/svg/innumbers_graph/2022.svg");
}

.graph2023 {
    background-image: url("../../assets/svg/innumbers_graph/2023.svg");
}

.graph2024 {
    background-image: url("../../assets/svg/innumbers_graph/2024.svg");
}

.graphYear {
    font-weight: 600;
    text-align: center;
    margin-top: 5px;
}

.eventsTitle {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 16px 0;
}

.events {
    position: relative;
}

.events::before {
    position: absolute;
    top: -13px;
    bottom: 8px;
    left: 5px;
    width: 1.815px;
    content: "";
    background: var(--color-blue);
    border-radius: 0 0 5px 5px;
}

.eventList {
    list-style-type: none;
    padding-left: 20px;
    margin: 20px 0;
    color: var(--color-blue);
}

.eventItem {
    position: relative;
    margin-bottom: 20px;
}

.eventItem span {
    font-size: 18px;
    font-weight: 600;
}

.eventItem p {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: var(--color-black);
}

.eventItem::before {
    top: 5px;
    left: -21px;
    width: 14px;
    height: 14px;
    content: "";
    background: var(--color-blue);
    position: absolute;
    border-radius: 50%;
}

.closeButton {
    background-image: url(../../../public/img/pc/interview_modal_close.svg);
    background-size: contain;
    position: absolute;
    width: 50px;
    height: 50px;
    right: 20px;
    top: 20px;
    cursor: pointer;
}