@import "../../styles/globalFunc.scss";

.background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: var(--modal-zindex);
    overflow-y: scroll;
}

.modalWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 88px;
    background-color: white;
    display: flex;
    border-radius: 20px;
    overflow-y: auto;
    box-sizing: border-box;
    max-height: 100vh;
}

.memberImg {
    width: 280px;
    border-radius: 10px;
}

.rightWrapper {
    margin-left: 48px;
    font-family: Noto Sans JP;
    font-style: normal;
    min-width: 290px;
}

.position {
    margin: 4px 0 8px 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 180%;
    letter-spacing: 0.45px;
}

.name {
    margin: 0 0 20px 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 1.1px;
}

.detail {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
}

.closeButton {
    background-image: url(../../../public/img/pc/interview_modal_close.svg);
    background-size: contain;
    position: absolute;
    width: 50px;
    height: 50px;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.modalOpen {
    display: block;
    position: absolute;
}

.modalHidden {
    display: none;
}

@media screen and (max-width: 1200px) {
    .modalWrapper {
        padding: spPx(30px) spPx(43px);
        flex-direction: column;
    }

    .position {
        margin: spPx(15px) 0 0 0;
        font-size: 1.75rem;
    }

    .rightWrapper {
        margin-left: 0;
        min-width: auto;
    }

    .memberImg {
        width: spPx(255px);
        border-radius: spPx(10px) spPx(10px) 0 0;
    }

    .name {
        font-size: 2.5rem;
        margin-bottom: 0;
    }

    .detail {
        font-size: 1.8rem;
        margin: 0;
        line-height: 155%;
    }

    .closeButton {
        right: spPx(7px);
        top: spPx(7px);
        width:  spPx(30px);
        height:  spPx(30px);
    }
}