:root {
    --pc-content-width: 1120px;
    --pc-content-padding: 80px;
    --pc-bg-width: 1700px;
    --sp-bg-width: 780px;
    --sp-content-padding: 24px;
    --sp-content-width: 684px;
    --color-orange: #FFA320;
    --color-blue: #09B5C8;
    --color-blue-bg: #EAF6F9;
    --color-blue-transparent: rgba(9,181,200,0.5);
    --color-middle-light-blue: #66D3DF;
    --color-light-blue: #B2E9EF;
    --color-gray: #6B6969;
    --color-black: #202020;
    --color-red: #E8525F;
    --color-yellow: #FFE144;
    --color-beige: #F8F1E7;
    --color-white: #FFFFFF;
    --modal-zindex: 100;
}

h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 4px;
}

html {
    font-size: 16px;
}

@media screen and (min-width: 1700px) {
    :root {
        --pc-content-standard: 1px;
    }
}

@media screen and ( max-width: 1700px) {
    :root {
        --pc-content-standard: calc(1 / 1700 * 100vw);
    }
}

@media screen and (min-width: 780px) and ( max-width: 1200px) {
    html {
        font-size: 16px;
    }
}


@media screen and (max-width: 780px) {
    html {
        font-size: calc(16 / 780 * 100vw);
    }
}