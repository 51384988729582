@import "../styles/globalFunc.scss";

.headerWrapper {
    position: fixed;
    top: 0;
    max-width: var(--pc-bg-width);
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 24px calc(var(--pc-content-standard) * 50);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.iimonLogo {
    width: 122px;
}

.links {
    display: flex;
    height: 50px;
    align-items: center;
    column-gap: calc(var(--pc-content-standard) * 45);
}

.links a {
    text-decoration: none;
    color: var(--color-black);
}

.items {
    display: flex;
    list-style: none;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.8px;
    column-gap: calc(var(--pc-content-standard) * 45);
    padding: 0;
    margin: 0;
}

@media (hover: hover) {
    .items > li:hover {
        transform: scale(1.1);
        opacity: 0.6;
        transition: all 0.2s ease-in-out;
    }
}

.recruitLink {
    background-color: var(--color-white);
    height: 100%;
    padding: 0 32px;
    line-height: 50px;
    text-align: center;
    border-radius: 25px;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.6px;
    box-sizing: border-box;
}

@media (hover: hover) {
    .recruitLink:hover {
        color: white;
        background-color: var(--color-orange);
        transition: all 0.2s ease-in-out;
    }
}

.menuButton {
    display: none;
}

@media screen and (min-width: 1200px) and (max-width: 1350px){
    // ヘッダーが崩れないように調整
    .items {
        column-gap: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .headerWrapper {
        max-width: var(--sp-bg-width);
        padding: spPx(12px);
    }

    .iimonLogo {
        width: spPx(99px);
    }

    .links {
        height: spPx(48px);
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
        column-gap: spPx(14px);
    }

    .recruitLink {
        font-size: 1.9rem;
        padding: 0 spPx(32px);
        box-sizing: border-box;
        border-radius: spPx(25px);
        line-height: spPx(48px);
    }

    .menuButton {
        display: inline;
        width: spPx(48px);
        height: spPx(48px);
        background-image: url(../../public/img/sp/header_menu_bg.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
        cursor: pointer;
    }

    .menuButtonInner {
        width: 100%;
        height: 100%;
        background-image: url(../../public/img/sp/header_menu_open.svg);
        background-size: spPx(24px) spPx(24px);
        background-position: center;
        background-repeat: no-repeat;
    }

    .menuBg {
        max-width: var(--sp-bg-width);
        width: 100%;
        height: 100vh;
        background-color: var(--color-blue);
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: spPx(100px) spPx(80px) 0 spPx(80px);
        box-sizing: border-box;
    }

    .closeButton {
        width: spPx(48px);
        height: spPx(48px);
        background-image: url(../../public/img/sp/header_menu_bg.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
        cursor: pointer;
        top: spPx(12px);
        right: spPx(12px);
        position: absolute;
    }

    .closeButtonInner {
        width: 100%;
        height: 100%;
        background-image: url(../../public/img/sp/header_menu_close.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .menuItems {
        list-style: none;
        font-family: Montserrat;
        font-size: 1.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.75px;
        text-align: center;
        padding: 0;
    }

    .menuItem {
        margin-bottom: spPx(30px);
    }

    .menuItem a {
        text-decoration: none;
        color: var(--color-black);
    }

    .menuOpen {
        display: block;
    }

    .menuHidden {
        display: none;
    }
}