@import "../styles/globalFunc.scss";

.visionWrapper {
    overflow-x: hidden;
    margin-top: -86px;
}

.contentsWrapper {
    max-width: 417px;
    text-align: left;
    position: absolute;
    top: 139px;
    left: 1007px;
}

.rightPic0Bg {
    position: absolute;
    background-image: url(../../public/img/pc/vision_0_bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 213px;
    height: 213px;
    top: 360px;
    left: -170px;
}

.rightPic0 {
    background-image: url(../../public/img/pc/vision_0.png);
    background-repeat: no-repeat;
    background-size: 212px auto;
    background-position: 2px 1px;
    width: 100%;
    height: 100%;
}

.rightPic1Bg {
    position: absolute;
    background-image: url(../assets/vision/vision_1_bg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 298px;
    height: 298px;
    top: 380px;
    right: 0;
}

.rightPic1 {
    background-image: url(../assets/vision/vision_1.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.visionTitle {
    margin: 0 0 40px 0;
    font-size: 40px;
    font-weight: 700;
}

.visionDetail {
    font-family: Noto Sans JP;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 250%;
    letter-spacing: 0.6px;
}

.visionDetail span {
    font-weight: bold;
    color: var(--color-orange);
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    letter-spacing: 0.9px;
}

.visionDeatailPolicy {
    line-height: 150%;
    margin-top: 20px;
}

.visionIllustration {
    position: absolute;
    width: 334px;
    height: 343px;
    top: 454px;
    left: 319px;
}

.visionIllustrationBulb {
    position: absolute;
    width: 100px;
    top: 395px;
    left: 470px;
}

.visionIllustrationBulbVibration {
    animation: vibration .07s  infinite;
    animation-iteration-count: 10;
}

@keyframes vibration {
    0% {transform: translate(0px, 0px) rotateZ(0deg)}
    25% {transform: translate(2px, 2px) rotateZ(1deg)}
    50% {transform: translate(0px, 2px) rotateZ(0deg)}
    75% {transform: translate(2px, 0px) rotateZ(-1deg)}
    100% {transform: translate(0px, 0px) rotateZ(0deg)}
}

.visionIllustrationPeople {
    position: absolute;
    width: 265px;
    top: 525px;
    left: 390px;
}

.missionPcImg {
    width: calc(var(--pc-content-standard) * 860);
    position: absolute;
    border-radius: 20px 0px 0px 20px;
    top: 210px;
    right: 0;
}

.missionWrapper {
    max-width: var(--pc-bg-width);
    margin: 0 auto;
    text-align: center;
    height: 745px;
    background-image: url(../../public/img/pc/mission_bg_blue.svg);
    background-position: 113px center;
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    position: relative;
}

.bgYellow {
    background-image: url(../../public/img/pc/mission_bg.svg);
    background-repeat: no-repeat;
    height: 670px;
    width: 417px;
    position: absolute;
    top: 654px;
    right: 0;
}

.missionText {
    max-width: var(--pc-content-width);
    position: absolute;
    top: 105px;
    left: 287px;
}

.missionTitle {
    font-weight: 700;
    text-align: left;
    font-family: Montserrat;
    font-size: 40px;
    color: var(--color-yellow);
}

.missionSubTitle {
    font-weight: 700;
    font-size: 40px;
    text-align: left;
    font-family: Noto Sans JP;
    color: var(--color-white);
    line-height: 180%;
}

.missionSubTitleAnim {
    transition: all 0.6s 0s ease-in;
    clip-path: inset(0 0 100% 0);
}

.missionSubTitleAnimVisible {
    clip-path: inset(0 0 0 0);
}

.styleWrapper {
    max-width: var(--pc-bg-width);
    margin: 0 auto;
    text-align: center;
}

.styleTitle {
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    margin: 177px auto 24px auto;
}

.nineStylesWrapper {
    width: 100%;
    position: relative;
}

.nineStylesInner {
    display: flex;
    overflow-x: scroll;
    list-style: none;
    gap: 40px;
}

.nineStylesBox {
    min-height: 472px;
    min-width: 418px;
    background-color: var(--color-beige);
    padding: 56px;
    border-radius: 30px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    margin: 10px;
    box-sizing: border-box;
}

.nineStylesTitle {
    color: var(--color-orange);
    font-size: 32px;
    font-weight: bold;
    margin: 0 auto 40px auto;
}

.nineStylesSubTitle {
    color: var(--color-black);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;
}

.nineStylesDetail {
    text-align: left;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
}

@media screen and (max-width: 1200px) {
    .visionWrapper {
        margin-top: spPx(-126px);
    }

    .bgBlueBottom {
        max-width: var(--sp-bg-width);
        width: 100%;
        height: spPx(80px);
        margin: -1px auto 0 auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
        background-image: url(../../public/img/sp/mission_bg_blue_bottom.png);
        position: relative;
        background-color: var(--color-white);
    }

    .contentsWrapper {
        max-width: calc(var(--sp-content-width) + var(--sp-content-padding)*2);
        padding: 30px var(--sp-content-padding) 15px var(--sp-content-padding);
        position: static;
        background-color: var(--color-white);
        text-align: center;
    }

    .rightPic0Bg {
        position: static;
        margin: spPx(15px) auto 0 spPx(10px);
        width: spPx(213px);
        height: spPx(213px);
        background-size: contain;
    }

    .rightPic0 {
        background-size: spPx(212px) auto;
        background-position: 2px 1px;
    }

    .rightPic1Bg {
        position: static;
        margin: spPx(20px) spPx(5px) 0 auto;
        width: spPx(235px);
        height: spPx(235px);
        background-size: spPx(235px) auto;
    }

    .rightPic1 {
        background-size: cover;
        background-position: center;
    }

    .bgBlueTop {
        max-width: var(--sp-bg-width);
        width: 100%;
        height: spPx(160px);
        margin: 0 auto -1px auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-image: url(../../public/img/sp/mission_bg_blue_top.png);
        position: relative;
    }

    .missionText {
        position: static;
        padding-top: 0;
    }

    .missionTitle {
        font-weight: 700;
        text-align: center;
        font-family: Montserrat;
        font-size: 4rem;
        color: var(--color-yellow);
        margin-top: 0;
    }

    .missionSubTitle {
        font-weight: 700;
        font-size: 3rem;
        text-align: center;
        font-family: Noto Sans JP;
        color: var(--color-white);
        margin-bottom: spPx(30px);
    }

    .visionTitle {
        margin: 0 0 spPx(15px) 0;
        font-size: 4rem;
    }

    .visionIllustrationBulb {
        position: static;
        display: block;
        width: spPx(120px);
        height: auto;
        margin: 0 auto spPx(20px) auto;
    }

    .visionIllustrationPeople {
        position: static;
        display: block;
        width: spPx(250px);
        margin: 0 auto spPx(20px) auto;
    }

    .visionDetail {
        font-size: 2.25rem;
    }

    .visionDetail span {
        font-size: 2.5rem;
    }

    .missionWrapper {
        max-width: var(--sp-bg-width);
        margin: 0 auto;
        background-image: none;
        text-align: center;
        height: auto;
        position: relative;
        background-color: var(--color-blue);
    }

    .bgYellow {
        height: spPx(380px);
        width: spPx(240px);
        background-size: contain;
        background-position: top right;
        top: spPx(100px);
        right: 0;
    }

    .missionPcImg {
        width: calc(100% - var(--sp-content-padding)*2);
        position: absolute;
        border-radius: 20px;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
    }

    .styleWrapper {
        max-width: var(--sp-bg-width);
        margin-bottom: spPx(80px);
        position: relative;
    }

    .styleTitle {
        font-size: 4rem;
        font-style: normal;
        font-weight: 700;
        margin: spPx(120px) auto spPx(10px) auto;
    }

    .styleSubTitle {
        font-size: 2rem;
        margin-top: 0;
    }

    .nineStylesWrapper {
        width: 100%;
        padding: 0 spPx(16px);
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
    }

    .nineStylesScroll {
        overflow: hidden;
    }

    .leftScroll {
        background-image: url(../../public/img/sp/scroll_button.svg);
        background-size: contain;
        position: absolute;
        top: spPx(200px);
        left: spPx(5px);
        width: spPx(40px);
        height: spPx(40px);
        transform: scale(-1, 1);
    }

    .rightScroll {
        background-image: url(../../public/img/sp/scroll_button.svg);
        background-size: contain;
        position: absolute;
        top: spPx(200px);
        right: spPx(5px);
        width: spPx(40px);
        height: spPx(40px);
    }

    .nineStylesInner {
        width: fit-content;
        padding: 0;
        flex-wrap: nowrap;
        overflow: hidden;
        column-gap: 0;
        transition: transform 0.5s 0s ease;
    }

    .nineStylesBoxWrapper {
        padding: spPx(10px);
        box-sizing: border-box;
    }

    .nineStylesBox {
        padding: spPx(25px);
        border-radius: 20px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
        margin: spPx(10px);
        box-sizing: border-box;
        min-width: spPx(324.5px);
        min-height: auto;
        height: spPx(400px);
    }

    .nineStylesTitle {
        color: var(--color-orange);
        font-size: 3.5rem;
        font-weight: bold;
        margin: 0 auto spPx(25px) auto;
    }

    .nineStylesSubTitle {
        color: var(--color-black);
        font-size: 2.25rem;
        font-weight: bold;
        margin-bottom: spPx(20px);
    }

    .nineStylesDetail {
        text-align: left;
        font-family: Noto Sans JP;
        font-size: 1.85rem;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
    }
}
