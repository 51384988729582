@import "../styles/globalFunc.scss";

.aboutBgTop {
    max-width: var(--pc-bg-width);
    width: 100%;
    height: 128px;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-image: url(../../public/img/pc/about_bg_top.png);
    position: relative;
}

.contentWrapper {
    max-width: var(--pc-bg-width);
    margin: 0 auto;
    background-color: var(--color-beige);
    padding: 139px 0 30px 0;
}

.contentWrapper h2 {
    font-size: 40px;
    text-align: center;
    margin: 0 0 64px 0;
}

.blueBg {
    position: absolute;
    background-image: url(../../public/img/pc/about_bg_blue.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    width: 30%;
    height: 704px;
    right: 0;
    top: -365px;
}

.contentInner {
    max-width: calc(var(--pc-content-width) + var(--pc-content-padding)*2);
    padding: 0 var(--pc-content-padding);
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.textWrapper {
    max-width: 576px;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    letter-spacing: 0.32px;
}

.imageWrapper {
    width: 500px;
}

.imageSmall {
    width: 270px;
    height: 140px;
    background-image: url(../../public/img/pc/about_img_small.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-left: auto;
    margin: 20px 0 20px auto;
    transition: all 0.5s 0s ease-out;
    clip-path: circle(0 at 50% 50%);
}

.imageSmallVisible {
    clip-path: circle(270px at 50% 50%);
}

.imageLarge {
    width: 350px;
    height: 200px;
    background-image: url(../../public/img/pc/about_img_large.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    transition: all 0.5s 0s ease-out;
    clip-path: circle(0 at 50% 50%);
}

.imageLargeVisible {
    clip-path: circle(350px at 50% 50%);
}

.aboutBgBottom {
    max-width: var(--pc-bg-width);
    width: 100%;
    height: 228px;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url(../../public/img/pc/about_bg_bottom.png);
    position: relative;
}

@media screen and (max-width: 1200px) {
    .aboutBgTop {
        max-width: var(--sp-bg-width);
        width: 100%;
        height: spPx(50px);
        margin: spPx(20px) auto 0 auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-image: url(../../public/img/sp/about_bg_top.png);
        position: relative;
    }

    .blueBg {
        width: spPx(195px);
        height: spPx(200px);
        right: 0;
        top: spPx(-70px);
        background-position: center;
    }

    .contentWrapper {
        max-width: var(--sp-bg-width);
        margin: 0 auto;
        padding: spPx(50px) 0 spPx(100px) 0;
        background-color: var(--color-beige);
        background-image: none;
    }

    .contentWrapper h2 {
        font-size: 4rem;
        margin: 0 0 spPx(10px) 0;
    }

    .contentInner {
        flex-direction: column;
        max-width: calc(var(--sp-content-width) - var(--sp-content-padding)*2);
        padding: 0 var(--sp-content-padding);
    }

    .textWrapper {
        font-size: 2rem;
        font-weight: 500;
        line-height: 200%;
        letter-spacing: 0.04rem;
    }

    .imageWrapper {
        width: 100%;
    }

    .imageSmall {
        width: spPx(230px);
        height: spPx(150px);
    }

    .imageSmallVisible {
        clip-path: circle(spPx(230px) at 50% 50%);
    }

    .imageLarge {
        width: spPx(280px);
        height: spPx(190px);
    }

    .imageLargeVisible {
        clip-path: circle(spPx(280px) at 50% 50%);
    }

    .aboutBgBottom {
        max-width: var(--sp-bg-width);
        height: spPx(30px);
        width: 100%;
        margin: 0 auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
        background-image: url(../../public/img/sp/about_bg_bottom.png);
        position: relative;
    }
}