.footerWrapper {
    max-width: 1120px;
    margin: 88px auto 40px auto;
}

.footerInner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.iimonLogo {
    width: 198px;
}

.companyName {
    margin-bottom: 7px;
    font-weight: bold;
}

.items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 500px;
    list-style: none;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.75px;
    gap: 32px 50px;
    padding: 0;
    margin: 0;
    height: 136px;
}

.items a {
    text-decoration: none;
    color: var(--color-black);
}

.item {
    height: calc(25% - 32px);
}

.caption {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.28px;
    text-align: center;
    margin: 48px auto 40px auto;
}


@media screen and (max-width: 1200px) {
    .footerWrapper {
        margin-top: 20px;
    }

    .footerInner {
        max-width: calc(var(--sp-content-width) - var(--sp-content-padding)*2);
        padding: 0 var(--sp-content-padding);
        margin: 0 auto;
        justify-content: flex-start;
    }

    .iimonLogo {
        width: 50%;
        margin-bottom: 50px;
    }

    .address {
        font-size: 1.5rem;
        width: 60%;
    }

    .items {
        height: auto;
        font-size: 1.75rem;
        width: 50%;
        gap: 20px 88px;
    }

    .caption {
        font-size: 1.5rem;
    }
}
