@import "../styles/globalFunc.scss";

.title {
  font-size: 35px;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.7px;
  text-align: center;
  color: var(--color-white);
  margin: 0 auto 20px auto;
}

.jobList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  justify-content: space-between;
  width: var(--pc-content-width);
  flex-wrap: wrap;
  row-gap: 27px;
}

.link {
  text-decoration: none;
}

.bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 548px;
  height: 230px;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  font-weight: 700;
  color: var(--color-white);
  text-align: center;
}

.bg:hover {
  opacity: 0.9;
}

.inner {
  border: 1px solid var(--color-white);
  border-radius: 12px;
  width: 93%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bgImg00 {
  background-image: url("../assets/jpg/job_description/bg_00.jpg");
}

.bgImg01 {
  background-image: url("../assets/jpg/job_description/bg_01.jpg");
}

.bgImg02 {
  background-image: url("../assets/jpg/job_description/bg_02.jpg");
}


@media screen and (max-width: 1200px) {
  .title {
    font-size: 2.75rem;
    margin-bottom: spPx(35px);
  }

  .jobList {
    flex-direction: column;
    width: 90%;
    row-gap: spPx(18px);
  }

  .bg {
    width: 100%;
    height: spPx(142px);
    font-size: 2.75rem;
  }
}