@import "../../styles/globalFunc.scss";

.bgTop {
    background-image: url(../../../public/img/pc/interview_bg_top.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: var(--beige);
    max-width: var(--pc-bg-width);
    height: 173px;
    margin: -140px auto;
    position: relative;
}

.bgRed {
    background-image: url(../../../public/img/pc/interview_red.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    width: 31%;
    height: 700px;
    position: absolute;
    left: 0;
    top: -480px;
}

.contentWrapper {
    max-width: var(--pc-bg-width);
    margin: 126px auto 0 auto;
    position: relative;
    background-color: var(--color-blue);
}

.styleTitle {
    color: var(--color-yellow);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 4px;
    text-align: center;
    margin-bottom: 88px;
}

.subTitle {
    color: var(--color-white);
    font-family: Noto Sans JP;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.48px;
    text-align: center;
    margin-bottom: 56px;
}

.interviewWrapper {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px;
    max-width: var(--pc-content-width);
    margin: 0 auto 128px auto;
    padding: 0;
}

.interviewCard {
    width: 262px;
    height: 320px;
    background-color: var(--color-white);
    border-radius: 17.5px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}

@media (hover: hover) {
    .interviewCard:hover {
        opacity: 0.8;
        transition: opacity 0.2s ease-in-out;
    }
}

.interviewCard a {
    text-decoration: none;
    color: var(--color-black);
}

.interviewCard:nth-child(3n) {
    margin-right: 0;
}

.interviewImg {
    width: 100%;
    height: 100%;
}

.interviewSubTitleWrapper {
    position: relative;
}

.boardmemberWrapper {
    width: var(--pc-content-width);
    margin: 0 auto;
}

.boardmemberInner {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    gap: 24px;
    margin-bottom: 128px;
    padding-left: 0;
}

.boardmemberCard {
    width: 357px;
    height: 220px;
    background-color: var(--color-white);
    border-radius: 20px;
    box-sizing: border-box;
    text-align: center;
    font-family: Noto Sans JP;
    font-style: normal;
    cursor: pointer;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.08);
}

.boardmemberCard p {
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 0;
}

.boardmemberCard h3 {
    font-size: 22px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 1.1px;
    margin: 0;
}

.boardmemberCardImg {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.boardMemberInterview {
    list-style: none;
    padding: 0;
    display: block;
    margin-bottom: 0;
    padding-bottom: 60px;
}

.boardMemberInterviewLink {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    text-decoration: none;
    color: var(--color-black);
    width: 100%;
}

@media (hover: hover) {
    .boardMemberInterviewLink:hover {
        opacity: 0.8;
        transition: opacity 0.2s ease-in-out;
    }
}

.boardMemberInterviewCard {
    margin: 0 auto 40px auto;
    height: 150px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 20px;
    max-width: var(--pc-content-width);
    width: var(--pc-content-width);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.08);
}

.boardMemberInterviewImg {
    width: 430px;
    object-fit: cover;
}

.boardMemberInterviewTitle {
    box-sizing: border-box;
    font-family: Noto Sans JP;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    letter-spacing: 0.6px;
    margin: 0;
}

.boardMemberInterviewSubTitle {
    font-size: 20px;
    margin: 0 0 15px 0;
}

.boardMemberInterviewTitleWrapper {
    padding: 0 0 0 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-white);
    width: 100%;
}

.jobDescription {
    padding-bottom: 100px;
}

.bgBottom {
    background-image: url(../../../public/img/pc/interview_bg_bottom.png);
    background-size: contain;
    max-width: var(--pc-bg-width);
    height: 257px;
    margin: 0 auto;
    position: relative;
    background-repeat: no-repeat;
}

.bgYellow {
    background-image: url(../../../public/img/pc/interview_bg_yellow.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    width: 22%;
    height: 600px;
    position: absolute;
    left: 0;
    top: -100px;
}

@media screen and (max-width: 1200px) {
    .bgTop {
        background-image: url(../../../public/img/sp/interview_bg_top.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        max-width: var(--sp-bg-width);
        height: spPx(80px);
        margin: -1px auto;
        position: relative;
        background-color: var(--color-beige);
    }

    .bgRed {
        width: spPx(120px);
        top: spPx(-140px);
        background-position: top left;
    }

    .contentWrapper {
        max-width: var(--sp-bg-width);
        margin: 0 auto;
        position: relative;
        background-color: var(--color-blue);
        padding-top: 0;
    }

    .styleTitle {
        font-size: 4rem;
        margin: 0 0 30px 0;
        padding-top: spPx(30px);
    }

    .subTitle {
        font-size: 2.5rem;
        margin-bottom: spPx(20px);
    }

    .interviewWrapper {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: spPx(80px);
    }

    .interviewCard {
        width: spPx(342px);
        height: spPx(417px);
        border-radius: 22.5px;
        overflow: hidden;
    }

    .interviewCard a {
        display: block;
    }

    .interviewImg {
        height: 100%;
        width: 100%;
    }

    .boardmemberButtons {
        position: relative;
        width: 100%;
    }

    .boardmemberWrapper {
        overflow: visible;
        width: spPx(347px);
        margin: 0 auto;
    }

    .boardmemberInner {
        justify-content: flex-start;
        flex-direction: column;
        gap: spPx(24px);
        padding: 0;
        overflow: visible;
        margin-bottom: spPx(80px);
        transition: transform 0.5s 0s ease;
    }

    .boardmemberCardWrapper {
        display: flex;
        border-radius: 10px 10px 0px 0px;
    }

    .boardmemberCard {
        width: 100%;
        height: auto;
        border-radius: 35px;
    }

    .boardmemberCardImg {
        width: 100%;
    }

    .boardMemberInterview {
        padding-bottom: spPx(20px);
    }

    .boardmemberCard p {
        font-size: 1.75rem;
        margin-top: 0;
        line-height: spPx(25px);
    }

    .boardmemberCard h3 {
        font-size: 2.25rem;
    }

    .boardMemberInterviewLink {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
    }

    .boardMemberInterviewCard {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        width: 90%;
        height: 100%;
    }

    .boardMemberInterviewImg {
        width: 100%;
        height: spPx(210px);
    }

    .boardMemberInterviewTitle {
        padding: 0;
        box-sizing: border-box;
        font-size: 2.75rem;
        line-height: 160%;
    }

    .boardMemberInterviewSubTitle {
        font-size: 2.25rem;
    }

    .boardMemberInterviewTitleWrapper {
        padding: 0;
        margin: 0;
        height: auto;
        padding: spPx(24px);
        box-sizing: border-box;
    }

    .bgBottom {
        background-image: url(../../../public/img/sp/interview_bg_bottom.png);
        background-size: contain;
        max-width: var(--sp-bg-width);
        height: spPx(120px);
        margin: -1px auto;
        position: relative;
        background-repeat: no-repeat;
    }

    .bgYellow {
        width: spPx(125px);
        height: spPx(210px);
        top: spPx(-50px);
    }
}