@import "../styles/globalFunc.scss";

.contentWrapper {
    max-width: var(--pc-bg-width);
    background-color: var(--color-beige);
    margin: -80px auto 0 auto;
    position: relative;
    padding: 155px 0 240px;
}

.bgBlue {
    background-image: url(../../public/img/pc/culture_bg_blue.svg);
    background-position: center right;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 30%;
    height: 708px;
    top: -308px;
    right: 0;
}

.title {
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 4px;
    text-align: center;
}

.serviceInner {
    width: 1120px;
    margin: 64px auto 0 auto;
}

.buisinessTitle {
    padding: 80px 0 40px 0;
}

.buisinessBox {
    border-radius: 50px;
    background: #FFF;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    font-family: Noto Sans JP;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    letter-spacing: 1.1px;
    text-align: center;
    position: relative;
}

.buisinessBox span {
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    color: var(--color-red);
    margin: 0 10px;
}

.buisinessBox span .percent {
    font-size: 24px;
    display: inline-block;
}

.buisinessInner {
    display: flex;
    justify-content: space-between;
    padding-bottom: 80px;
    width: 820px;
    margin: 0 auto;
}

.buisinessInner li {
    text-align: left;
}

.task {
    color: var(--color-orange);
    text-align: center;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.8px;
    height: 110px;
    background-image: url(../../public/img/pc/service_brackets.svg);
    background-position: center 31px;
    background-size: 243px 68px;
    background-repeat: no-repeat;
    margin: 0;
}

.taskList {
    font-family: Noto Sans JP;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    letter-spacing: 0.54px;
    margin: 0;
    padding: 0;
}

.taskList li {
    list-style-type: none;
    position: relative;
    padding-left: 1em;
}

.taskList li:before {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    left: 0;
    top: 0.8em;
    content: "";
    background: var(--color-blue);
}

.pie {
	position: relative;
	margin-left: auto;
	width: 304px;
	height: 304px;
	background-image: conic-gradient(var(--color-blue) 0% 50%, var(--color-blue-transparent) 50% 100%);
	border-radius: 50%;
}

.pie span {
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 2.4px;
}

.serviceIllustration {
    position: absolute;
    bottom: 84px;
    right: 460px;
    width: 130px;
    height: 148px;
}

.triangle {
    width: 0;
    border-top: 25px solid #B8B7B7;
    border-right: 25px solid transparent;
    border-left: 25px solid transparent;
    margin: 32px auto;
}

.resolution {
    text-align: center;
    font-family: Noto Sans JP;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 1.2px;
}

.resolution span {
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 2.4px;
}

.figureBox {
    border-radius: 50px;
    background: #FFF;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    font-family: Noto Sans JP;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    letter-spacing: 1.1px;
    text-align: center;
    position: relative;
    padding: 80px 0;
}

.figureBox p {
    font-family: Noto Sans JP;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    letter-spacing: 0.9px;
    margin-bottom: 40px;
}

.serviceFigure {
    width: 958px;
    height: 491px;
}

.usages {
    display: flex;
    justify-content: space-between;
    margin: 40px auto 16px auto;
}

.usageBox {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    width: 346px;
    height: 161px;
    text-align: center;
    font-family: Noto Sans JP;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.6px;
    padding-top: 40px;
}

.usageBox span {
    color: var(--color-red);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 1.8px;
}

.usagePersistence {
    font-size: 60px;
}

.usageCount {
    font-size: 49px;
}

.usageStore {
    font-size: 55.5px;
}

.annotation {
    display: block;
    text-align: right;
}

@media screen and (max-width: 1200px) {
    .contentWrapper {
        max-width: var(--sp-bg-width);
        margin: 0 auto;
        position: relative;
        padding: spPx(70px) 0 spPx(40px) 0;
    }

    .bgBlue {
        background-image: url(../../public/img/sp/culture_bg_blue.svg);
        width: spPx(120px);
        top: spPx(-90px);
        background-position: top right;
    }

    .serviceInner {
        width: 100%;
        margin: 40px auto 0 auto;
    }

    .buisinessInner {
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        width: 100%;
        margin: 0 auto;
        flex-direction: column-reverse;

    }

    .title {
        font-size: 4rem;
    }

    .buisinessBox {
        font-size: 2rem;
        border-radius: 20px;
    }

    .buisinessBox span {
        font-size: 4rem;
    }

    .buisinessBox .percent {
        font-size: 2.5rem;
    }

    .taskRate {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .serviceIllustration {
        position: static;
        width: spPx(130px);
        height: auto;
    }

    .pie {
        margin-left: spPx(50px);
        width: spPx(126px);
        height: spPx(126px);
    }

    .pie span {
        font-size: 2.5rem;
        right: spPx(-1px);
    }

    .taskList {
        margin: 0 0 0 30px;
        font-size: 2rem;
        height: spPx(160px);
    }

    .taskList li:before {
        width: spPx(8px);
        height: spPx(8px);
    }

    .task {
        margin-top: 41px;
        font-size: 2rem;
        height: spPx(100px);
        background-position: center bottom;
        background-size: spPx(243px) spPx(68px);
    }

    .serviceFigure {
        width: 100%;
        height: auto;
    }

    .figureBox {
        padding: 48px var(--sp-content-padding);
        box-sizing: border-box;
    }

    .figureBox p {
        font-size: 2rem;
    }

    .usages {
        flex-direction: column;
        row-gap: 24px;
        overflow: hidden;
    }

    .usageBox {
        height: auto;
        padding: var(--sp-content-padding) 32px;
        width: spPx(342px);
        box-sizing: border-box;
        margin: 0 auto;
        font-size: 2.25rem;
    }

    .usageBox span {
        font-size: 5rem;
        line-height: 6rem;
    }

    .annotation {
        padding: 0 var(--sp-content-padding);
        font-size: 1.75rem;
    }

    .triangle {
        border-top: spPx(30px) solid #B8B7B7;
        border-right: spPx(30px) solid transparent;
        border-left: spPx(30px) solid transparent;
        margin: spPx(24px) auto;
    }

    .resolution {
        font-size: 2.5rem;
        line-height: 4.5rem;
    }

    .resolution span {
        font-size: 4.5rem;
    }
}