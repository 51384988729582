@import "../../styles/globalFunc.scss";

.figureNumber {
    --figure-bottom: 80px;
}

.contentWrapper {
    max-width: var(--pc-bg-width);
    margin: 0 auto 100px auto;
    padding-top: 70px;
    position: relative;
}

.contentWrapper h2 {
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 4px;
    margin-bottom: 24px;
}

.contentWrapper h3 {
    text-align: center;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    letter-spacing: 0.8px;
    margin-bottom: 56px;
}

.figureNumber {
    max-width: var(--pc-content-width);
    height: 732px;
    background-color: var(--color-beige);
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 100px;
    background-image: url("../../assets/svg/innumbers_graph/graph_base.svg");
    background-repeat: no-repeat;
    background-position: center bottom 53px;
    position: relative;
}

.figure2025 {
    background-image: url("../../assets/svg/innumbers_graph/2025.svg");
    background-repeat: no-repeat;
    width: 104px;
    height: 601px;
    position: absolute;
    left: 929px;
    bottom: var(--figure-bottom);
}

.figure2024 {
    background-image: url("../../assets/svg/innumbers_graph/2024.svg");
    background-repeat: no-repeat;
    width: 100px;
    height: 527px;
    position: absolute;
    left: 792px;
    bottom: var(--figure-bottom);
    cursor: pointer;
}

.figure2023 {
    background-image: url("../../assets/svg/innumbers_graph/2023.svg");
    background-repeat: no-repeat;
    width: 100px;
    height: 306px;
    position: absolute;
    left: 650px;
    bottom: var(--figure-bottom);
    cursor: pointer;
}

.figure2022 {
    background-image: url("../../assets/svg/innumbers_graph/2022.svg");
    background-repeat: no-repeat;
    width: 100px;
    height: 116px;
    position: absolute;
    left: 513px;
    bottom: var(--figure-bottom);
    cursor: pointer;
}

.figure2021 {
    background-image: url("../../assets/svg/innumbers_graph/2021.svg");
    background-repeat: no-repeat;
    width: 100px;
    height: 56px;
    position: absolute;
    left: 372px;
    bottom: var(--figure-bottom);
    cursor: pointer;
}

.figure2020 {
    background-image: url("../../assets/svg/innumbers_graph/2020.svg");
    background-repeat: no-repeat;
    width: 100px;
    height: 46px;
    position: absolute;
    left: 231px;
    bottom: var(--figure-bottom);
    cursor: pointer;
}

.figure2019 {
    background-image: url("../../assets/svg/innumbers_graph/2019.svg");
    background-repeat: no-repeat;
    width: 100px;
    height: 46px;
    position: absolute;
    left: 92px;
    bottom: var(--figure-bottom);
    cursor: pointer;
}

.caption {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
}

.ratioValue {
    margin-top: -6px;
}

.ratio {
    max-width: var(--pc-content-width);
    margin: 40px auto 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.ratioBox {
    background-color: var(--color-beige);
    padding: 40px 22px 40px 22px;
    box-sizing: border-box;
    border-radius: 30px;
}

.ratioTitle {
    text-align: center;
    font-family: Noto Sans JP;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.6px;
    margin: 0 0 32px 0;
}

.ageRatio {
    position: relative;
    margin: 0 51px;
    width: 200px;
    height: 200px;
    background-image: conic-gradient(var(--color-blue) 0% 48.9%, var(--color-middle-light-blue) 48.9% 88.9%, var(--color-light-blue) 88.9% 100%);
    border-radius: 50%;
}

.ageRatio span {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.72px;
}

.twenties {
    right: 30px;
    top: 70px;
    color: var(--color-white);
}

.thirties {
    left: 30px;
    top: 90px;
    color: var(--color-white);
}

.forties {
    left: 60px;
    top: 10px;
    color: var(--color-blue);
}

.ageText {
    font-family: Noto Sans JP;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.6px;
    text-align: center;
    margin: 24px 0 0 0;
}

.ageText span {
    font-family: Montserrat;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 1.68px;
    color: var(--color-orange);
}

.genderRatio {
    position: relative;
    margin: 0 51px;
    width: 200px;
    height: 200px;
    background-image: conic-gradient(var(--color-blue) 0% 75.6%, var(--color-blue-transparent) 75.6% 100%);
    border-radius: 50%;
}

.genderRatio span {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.72px;
}

.male {
    right: 30px;
    top: 90px;
    color: var(--color-white);
}

.female {
    left: 40px;
    top: 35px;
    color: var(--color-white);
}

.departmentRatio {
    position: relative;
    margin: 0 51px;
    width: 200px;
    height: 200px;
    background-image: conic-gradient(var(--color-blue) 0% 20.1%, var(--color-middle-light-blue) 20.1% 56.4%, var(--color-light-blue) 56.4% 100%);
    border-radius: 50%;
}

.departmentRatio span {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.72px;
}

.backoffice {
    right: 45px;
    top: 23px;
    color: var(--color-white);
}

.salse {
    right: 25px;
    top: 110px;
    color: var(--color-white);
}

.engineer {
    left: 12px;
    top: 70px;
    color: var(--color-blue);
}

.departmentAnnotation {
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.42px;
    text-align: right;
}

.annotation {
    max-width: var(--pc-content-width);
    margin: 16px auto 0 auto;
    text-align: right;
}

@media screen and (max-width: 1200px) {
    .caption {
        font-size: 2rem;
    }

    .contentWrapper {
        max-width: var(--sp-bg-width);
        margin: 0px auto 0 auto;
        padding-top: spPx(50px);
    }

    .contentWrapper h2 {
        font-size: 4rem;
        margin-bottom: spPx(16px);
    }

    .contentWrapper h3 {
        font-size: 2rem;
        margin-bottom: spPx(35px);
    }

    .figureNumber {
        width: 100%;
        height: auto;
        border-radius: 30px;
        padding: var(--sp-content-padding) 0 spPx(37px) 0;
        background-image: url("../../assets/svg/innumbers_graph/sp_graph.svg");
        background-size: 88% auto;
        background-position: top spPx(40px) center;
    }

    .newsWrapper {
        overflow: hidden;
        padding-left: var(--sp-content-padding);
        position: relative;
        padding: 0 0 spPx(10px) var(--sp-content-padding);
    }

    .rightScroll {
        background-image: url(../../../public/img/sp/scroll_button.svg);
        background-size: contain;
        position: absolute;
        top: spPx(130px);
        right: spPx(5px);
        width: spPx(40px);
        height: spPx(40px);
    }

    .leftScroll {
        background-image: url(../../../public/img/sp/scroll_button.svg);
        background-size: contain;
        position: absolute;
        top: spPx(130px);
        left: spPx(5px);
        width: spPx(40px);
        height: spPx(40px);
        transform: scale(-1, 1);
    }

    .newsBase {
        display: flex;
        background-color: var(--color-white);;
        padding: spPx(20px);
        width: fit-content;
        border-radius: 20px;
        color: var(--color-blue);
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.08);
        transition: transform 0.5s 0s ease;
    }

    .newsTitle {
        text-align: center;
        margin-top: spPx(300px);
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 1.44px;
    }

    .yearItem {
        width: spPx(180px);
        flex-shrink: 0;
    }

    .yearItem ol {
        list-style-type: none;
        padding-left: spPx(22px);
    }

    .events {
        position: relative;
        margin: spPx(10px) 0;
    }

    .events::before {
        position: absolute;
        top: spPx(-10px);
        bottom: spPx(8px);
        left: spPx(6px);
        width: spPx(1.815px);
        content: "";
        background: var(--color-blue);
        border-radius: 0 0 5px 5px;
    }

    .yearItemYear {
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 1.28px;
        margin: 0;
    }

    .eventItem {
        position: relative;
        margin-bottom: spPx(20px);
    }

    .eventItem span {
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 1.12px;
    }

    .eventItem p {
        margin: 0;
        color: var(--color-black);
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 0.28px;
    }

    .eventItem::before {
        top: spPx(3px);
        left: spPx(-21px);
        width: spPx(12px);
        height: spPx(12px);
        content: "";
        background: var(--color-blue);
        position: absolute;
        border-radius: 50%;
    }

    .figureImg {
        max-width: var(--pc-content-width);
    }

    .ratio {
        column-gap: spPx(24px);
        overflow-x: scroll;
    }

    .ratioBox {
        padding: spPx(40px) spPx(22px) spPx(40px) spPx(22px);
    }

    .ratioTitle {
        font-size: 2.25rem;
    }

    .ageRatio {
        margin: 0 spPx(51px);
        width: spPx(200px);
        height: spPx(200px);
    }

    .ageRatio span {
        font-size: 4rem;
    }

    .twenties {
        right: spPx(25px);
        top: spPx(60px);
    }

    .thirties {
        left: spPx(30px);
        top: spPx(90px);
    }

    .forties {
        left: spPx(63px);
        top: spPx(6px);
    }

    .ageText {
        font-size: 2.5rem;
    }

    .ageText span {
        font-size: 5.125rem;
    }

    .annotation {
        font-size: 1.75rem;
        margin-right: spPx(24px);
    }

    .genderRatio {
        margin: 0 spPx(51px);
        width: spPx(200px);
        height: spPx(200px);
    }

    .genderRatio span {
        font-size: 4rem;
    }

    .male {
        right: spPx(30px);
        top: spPx(80px);
    }

    .female {
        left: spPx(40px);
        top: spPx(25px);
    }

    .departmentRatio {
        margin: 0 spPx(51px);
        width: spPx(200px);
        height: spPx(200px);
    }

    .departmentRatio span {
        font-size: 4rem;
    }

    .backoffice {
        right: spPx(45px);
        top: spPx(20px);
    }

    .salse {
        right: spPx(25px);
        top: spPx(105px);
    }

    .engineer {
        left: spPx(12px);
        top: spPx(60px);
    }
}