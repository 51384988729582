@import "../styles/globalFunc.scss";

.wrapper {
    max-width: var(--pc-bg-width);
    margin: 0 auto;
    position: relative;
    background-image: url(../../public/img/pc/kv_bg_orange.svg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 84% auto;
}

.inner {
    max-width: calc(var(--pc-content-width) - var(--pc-content-padding)*2);
    padding: 0 var(--pc-content-padding);
    height: 896px;
    margin: 0 auto;
}

.titleWrapper {
    padding: 337px 0 0 0;
}

.titleInner {
    position: relative;
}

.title {
    font-size: 56px;
    line-height: 1.8;
    margin: 0;
    opacity: 0;
}

.titleVisible {
    opacity: 1;
}

.titleTransition {
    transition: opacity 0.2s 0s ease-out;
}

.upperBandWrapper {
    width: 350px;
    height: 80px;
    top: 10px;
    overflow: hidden;
    position: absolute;
}

.upperBand {
    width: 350px;
    height: 80px;
    background-color: var(--color-orange);
    position: absolute;
    transition: transform 0.3s 0s ease-in-out;
    transform: translateX(-350px);
}

.upperBandMoveRight0 {
    transform: translateX(0px);
}

.upperBandMoveRight1 {
    transform: translateX(350px);
}

.lowerBandWrapper {
    width: 450px;
    height: 80px;
    top: 115px;
    overflow: hidden;
    position: absolute;
}

.lowerBand {
    width: 450px;
    height: 80px;
    background-color: var(--color-orange);
    position: absolute;
    transition: transform 0.3s 0s ease-in-out;
    transform: translateX(-450px);
}

.lowerBandMoveRight0 {
    transform: translateX(0px);
}

.lowerBandMoveRight1 {
    transform: translateX(450px);
}

.pic0Bg {
    position: absolute;
    background-image: url(../../public/img/pc/kv_0_bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 211px;
    height: 242px;
    top: 125px;
    right: 38%;
}

.pic0 {
    background-image: url(../../public/img/pc/kv_0.png);
    background-repeat: no-repeat;
    background-size: 222px auto;
    background-position: -11px -51px;
    width: 100%;
    height: 100%;
}

.pic1Bg {
    position: absolute;
    background-image: url(../../public/img/pc/kv_1_bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 335px;
    height: 260px;
    top: 125px;
    right: 10%;
}

.pic1 {
    background-image: url(../../public/img/pc/kv_1.png);
    background-repeat: no-repeat;
    background-size: 370px auto;
    background-position: -41px -48px;
    width: 100%;
    height: 100%;
}

.pic2Bg {
    position: absolute;
    background-image: url(../../public/img/pc/kv_2_bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 157px;
    height: 180px;
    top: 330px;
    right: 28%;
}

.pic2 {
    background-image: url(../../public/img/pc/kv_2.png);
    background-repeat: no-repeat;
    background-size: 169px auto;
    background-position: center -27px;
    width: 100%;
    height: 100%;
}

.pic3Bg {
    position: absolute;
    background-image: url(../../public/img/pc/kv_3_bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 152px;
    height: 205px;
    top: 460px;
    right: 16%;
}

.pic3 {
    background-image: url(../../public/img/pc/kv_3.png);
    background-repeat: no-repeat;
    background-size: 154px auto;
    background-position: -2px -1px;
    width: 100%;
    height: 100%;
}

.pic4Bg {
    position: absolute;
    background-image: url(../../public/img/pc/kv_4_bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 182px;
    height: 210px;
    top: 535px;
    right: 34%;
}

.pic4 {
    background-image: url(../../public/img/pc/kv_4.png);
    background-repeat: no-repeat;
    background-size: 180px auto;
    background-position: 1px -46px;
    width: 100%;
    height: 100%;
}

.illustration {
    position: absolute;
    width: 274px;
    top: 604px;
    left: calc(var(--pc-content-standard) * 430);
    opacity: 0;
    transition: opacity 0.6s 0s ease-in;
}

.illustrationVisible {
    opacity: 1;
}

@media screen and (max-width: 1200px) {
    .wrapper {
        max-width: var(--sp-bg-width);
        background-position: top right;
        background-image: url(../../public/img/sp/kv_bg_orange.svg);
        background-size: 100% auto;
    }

    .pic0Bg {
        top: spPx(80px);
        left: spPx(30px);
        width: spPx(120px);
        height: spPx(130px);
        background-size: contain;
    }

    .pic0 {
        background-size: spPx(120px) auto;
        background-position: spPx(-1.5px) spPx(-28px);
    }

    .pic1Bg {
        top: spPx(85px);
        right: spPx(5px);
        width: spPx(168px);
        height: spPx(130px);
        background-size: contain;
    }

    .pic1 {
        background-size: spPx(185px) auto;
        background-position: spPx(-20px) spPx(-23px);
    }

    .pic2Bg {
        top: spPx(190px);
        right: spPx(150px);
        width: spPx(90px);
        height: spPx(110px);
        background-size: contain;
    }

    .pic2 {
        background-size: spPx(90px) auto;
        background-position: center 0;
    }

    .pic3Bg {
        top: spPx(270px);
        right: spPx(50px);
        width: spPx(90px);
        height: spPx(120px);
        background-size: contain;
    }

    .pic3 {
        background-size: spPx(91px) auto;
    }

    .pic4Bg {
        top: spPx(300px);
        left: spPx(65px);
        width: spPx(95px);
        height: spPx(110px);
        background-size: contain;
    }

    .pic4 {
        background-size: spPx(95px) auto;
        background-position: spPx(1px) spPx(-25px);
    }


    .inner {
        max-width: calc(var(--sp-content-width) - var(--sp-content-padding)*2);
        padding: 0 var(--sp-content-padding);
        height: auto;
    }

    .titleWrapper {
        padding-top: spPx(455px);
    }

    .title {
        font-family: Noto Sans JP;
        font-size: 4.5rem;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 3.6px;
        margin: 0;
        padding: 0;
    }

    .upperBandWrapper {
        width: spPx(240px);
        height: spPx(50px);
        top: spPx(10px);
    }

    .upperBand {
        width: spPx(240px);
        height: spPx(50px);
    }

    .upperBandMoveRight1 {
        transform: translateX(spPx(240px));
    }

    .lowerBandWrapper {
        width: spPx(320px);
        height: spPx(50px);
        top: spPx(80px);
    }

    .lowerBand {
        width: spPx(320px);
        height: spPx(50px);
    }

    .lowerBandMoveRight1 {
        transform: translateX(spPx(320px));
    }

    .illustration {
        display: block;
        position: relative;
        width: spPx(223px);
        margin: spPx(55px) auto spPx(20px) auto;
        inset: auto;
    }
}