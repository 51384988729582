@import "../styles/globalFunc.scss";

.welfareBgTop {
    max-width: var(--pc-bg-width);
    height: 190px;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-image: url(../../public/img/pc/welfare_bg_top.png);
    position: relative;
}

.bgBlue {
    background-image: url(../../public/img/pc/welfare_bg_blue.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    width: 30%;
    height: 685px;
    position: absolute;
    right: 0;
    top: -360px;
}

.contentWrapper {
    max-width: var(--pc-bg-width);
    margin: 0 auto;
    position: relative;
    background-color: var(--color-beige);
}

.welfareItems {
    max-width: var(--pc-content-width);
    margin: 0 auto;
    text-align: center;
}

.welfareItems h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 4px;
    margin: 0 0 5px 0;
    padding-top: 20px;
}

.welfareSubtitle {
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    letter-spacing: 0.8px;
    margin: 0 0 25px 0;
}

.welfareItems p {
    font-family: Noto Sans JP;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    letter-spacing: 0.85px;
    margin-bottom: 64px;
}

.welfareKind {
    font-size: 20px;
    margin-top: 45px;
}

.items {
    list-style: none;
    padding: 0;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.8px;
    column-count: 4;
    column-gap: 13px;
    margin-bottom: 0;
    text-align: left;
}

.item {
    width: 270px;
    height: 62px;
    border-radius: 40px;
    padding: 10px 24px;
    box-sizing: border-box;
    background-color: var(--color-orange);
    color: var(--color-white);
    margin: 0 0 16px 0;
    page-break-inside: avoid;
    break-inside: avoid;
    display: flex;
    align-items: center;
}

.itemIcon {
    margin-right: 14px;
}

.itemTitleWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.itemAddition {
    font-size: 12px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.6px;
}

.welfareImages {
    display: flex;
    list-style: none;
    padding: 40px 30px 90px 30px;
    column-gap: 40px;
    background-color: var(--color-beige);
    margin: 0 auto;
    overflow-x: scroll;
    max-width: var(--pc-bg-width);
    box-sizing: border-box;
}

.welfareImageRadius {
    width: 510px;
    height: 318px;
    border-radius: 16px;
    object-fit: cover;
}

.welfareBgBottom {
    max-width: var(--pc-bg-width);
    height: 129px;
    margin: -1px auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url(../../public/img/pc/welfare_bg_bottom.png);
    background-color: var(--color-orange);
}

@media screen and (max-width: 1200px) {
    .bgBlue {
        width: spPx(130px);
        height: spPx(220px);
        top: spPx(-120px);
    }

    .welfareBgTop {
        max-width: var(--sp-bg-width);
        background-image: url(../../public/img/sp/welfare_bg_top.png);
        margin-top: -1px;
    }

    .contentWrapper {
        max-width: var(--sp-bg-width);
        padding-top: spPx(47px);
    }

    .welfareItems {
        max-width: calc(var(--sp-content-width) + var(--sp-content-padding)*2);
        padding: 0 var(--sp-content-padding);
    }

    .welfareItems h2 {
        font-size: 4rem;
    }

    .welfareItems h3 {
        font-size: 2rem;
    }

    .welfareItems p {
        font-size: 2rem;
        max-width: var(--sp-content-width);
        margin: 0 auto;
        text-align: left;
    }

    .welfareKind {
        margin-top: 30px;
    }

    .items {
        column-count: 2;
        font-size: 1.5rem;
        font-weight: 700;
        padding: 0 0 var(--sp-content-padding) 0;
        gap: spPx(8px);
    }

    .item {
        width: 100%;
        height: spPx(52px);
        border-radius: spPx(30px);
        padding: spPx(10px) spPx(10px);
        margin-bottom: spPx(8px);
    }

    .itemIcon {
        margin-right: spPx(3px);
        height: spPx(24px);
        width: spPx(24px);
    }

    .itemAddition {
        font-size: 1rem;
    }

    .welfareImagesWrapper {
        max-width: var(--sp-bg-width);
        background-color: var(--color-beige);
        margin: 0 auto;
        position: relative;
    }

    .welfareImagesInner {
        width: spPx(365px);
        margin: 0 auto;
        overflow-x: hidden;
    }

    .welfareImages {
        max-width: var(--sp-bg-width);
        padding: spPx(24px) spPx(12px) spPx(60px) spPx(12px);
        overflow-x: visible;
        column-gap: 0;
        padding: spPx(12px) 0 spPx(60px) 0;
        transition: transform 0.5s 0s ease;
    }

    .welfareImage {
        padding-right: spPx(12px);
        width: spPx(365px);
    }

    .welfareImageRadius {
        border-radius: 16px;
        width: spPx(365px);
        height: spPx(210px);
        object-fit: cover;
    }

    .leftScroll {
        background-image: url(../../public/img/sp/scroll_button.svg);
        background-size: contain;
        position: absolute;
        top: spPx(90px);
        left: spPx(5px);
        width: spPx(40px);
        height: spPx(40px);
        transform: scale(-1, 1);
    }

    .rightScroll {
        background-image: url(../../public/img/sp/scroll_button.svg);
        background-size: contain;
        position: absolute;
        top: spPx(90px);
        right: spPx(5px);
        width: spPx(40px);
        height: spPx(40px);
    }

    .welfareBgBottom {
        max-width: var(--sp-bg-width);
        background-image: url(../../public/img/sp/welfare_bg_bottom.png);
        height: spPx(75px);
        margin-bottom: -1px;
    }
}