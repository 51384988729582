@import "../styles/globalFunc.scss";

.contentWrapper {
    max-width: var(--pc-bg-width);
    margin: 0 auto;
    position: relative;
    background-color: var(--color-orange);
    padding: 0 0 70px 0;
}

.recruitBgBottom {
    max-width: var(--pc-bg-width);
    height: 116px;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url(../../public/img/pc/recruit_bg_bottom.png);
    background-color: var(--color-beige);
}

.blogLinkWrapper {
    height: 295px;
    background-color: var(--color-beige);
    max-width: var(--pc-bg-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 24px 0 0 0;
}

.blogLinkBox {
    border: 3px solid var(--color-blue);
    justify-content: center;
    align-items: center;
    width: 768px;
    height: 260px;
    border-radius: 13px;
    background: var(--color-blue-bg);
    box-shadow: 0px 6px 0px 0px rgba(55, 93, 97, 0.10);
    position: relative;
    color: var(--color-black);
    text-decoration: none;
}

.blogLinkBoxInner {
    margin: 26px 0 0 30px;
    width: 325px;
}

.blogLinkBoxInner h2 {
    font-size: 46px;
    font-weight: 700;
    margin: 0;
}

.blogLinkBoxInner > .blogLinkSubTitle {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
}

.blogLinkBoxInner p {
    font-size: 17px;
    font-weight: 400;
    margin-top: 20px;
    letter-spacing: 0.85px;
    line-height: 180%;
}

.blogLinkImage {
    width: 354px;
    height: 290px;
    background-image: url(../assets/recruit/blog_link_illustration_pc.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    bottom: 0;
}

.blogBgBottom {
    background-image: url(../../public/img/pc/blog_bg_bottom.svg);
    height: 148px;
    max-width: var(--pc-bg-width);
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
}

@media screen and (max-width: 1200px) {
    .contentWrapper {
        max-width: var(--sp-bg-width);
        padding: 0 0 30px 0;
    }

    .recruitWrapper {
        flex-direction: column;
    }

    .recruitBgBottom {
        max-width: var(--sp-bg-width);
        height: spPx(80px);
        background-image: url(../../public/img/sp/recruit_bg_bottom.png);
        margin-top: -1px;
    }

    .blogLinkWrapper {
        height: auto;
        max-width: var(--sp-bg-width);
    }

    .blogLinkBox {
        height: spPx(313px);
        margin: 0 spPx(30px) spPx(40px) spPx(30px);
    }

    .blogLinkBoxInner {
        margin: spPx(16px) 0;
        width: 100%;
    }

    .blogLinkBoxInner h2 {
        text-align: center;
        font-size: 4.5rem;
    }

    .blogLinkBoxInner > .blogLinkSubTitle {
        font-size: 2rem;
        margin: 0 auto;
    }

    .blogLinkBoxInner p {
        text-align: center;
        font-size: 1.5rem;
        width: spPx(295px);
        margin: 15px auto;
    }

    .blogLinkImage {
        width: 100%;
        height: spPx(150px);
        background-image: url(../assets/recruit/blog_link_illustration_sp.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        position: relative;
        right: 0;
        bottom: 0;
    }

    .blogBgBottom {
        max-width: var(--sp-bg-width);
        height: spPx(50px)
    }
}