@import "../styles/globalFunc.scss";

.cultureBgTop {
    max-width: var(--pc-bg-width);
    height: 305px;
    margin: 160px auto 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-image: url(../../public/img/pc/culture_bg_top.png);
    position: relative;
}

.contentWrapper {
    max-width: var(--pc-bg-width);
    background-color: var(--color-orange);
    margin: 0 auto;
    position: relative;
}

.cultureInner {
    max-width: calc(var(--pc-content-width) + var(--pc-content-padding)*2);
    padding: 0 var(--pc-content-padding);
    margin: 0 auto;
}

.cultureInner h2 {
    text-align: center;
    font-size: 40px;
    margin: 0 0 24px 0;
}

.cultureInner h3 {
    text-align: center;
    font-size: 16px;
    margin: 0 0 64px 0;
}

.cultureText {
    font-family: Noto Sans JP;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    letter-spacing: 0.85px;
    text-align: center;
    margin-bottom: 70px;
}

.cultureWrapper {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 30px;
}

.cultureBox {
    width: 347px;
    height: 347px;
    text-align: center;
    padding: 40px 56px 0 56px;
    box-sizing: border-box;
    background-image: url(../../public/img/pc/culture_item_bg.svg);
    background-position: center;
    background-size: contain;
}

.cultureTitle {
    color: var(--color-red);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.78px;
    margin-bottom: 32px;
}

.cultureDetail {
    font-family: Noto Sans JP;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    letter-spacing: 0.3px;
    text-align: left;
    margin: 0;
}

.cultureIllustration {
    width: 435px;
    margin: 0 auto;
    display: block;
}

.bgRed {
    background-image: url(../../public/img/pc/culture_bg_red.svg);
    background-position: center left;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 22%;
    height: 400px;
    top: -373px;
    left: 0;
}

.cultureBgBottom {
    max-width: var(--pc-bg-width);
    height: 205px;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url(../../public/img/pc/culture_bg_bottom.png);
    background-color: var(--color-beige);
    position: relative;
}

@media screen and (max-width: 1200px) {
    .cultureBgTop {
        max-width: var(--sp-bg-width);
        margin: 30px auto -1px auto;
        height: spPx(30px);
        background-image: url(../../public/img/sp/culture_bg_top.png);
    }

    .bgRed {
        background-image: url(../../public/img/sp/culture_bg_red.svg);
        background-position: left center;
        top: spPx(-90px);
        width: spPx(170px);
        height: spPx(250px);
    }

    .contentWrapper {
        max-width: var(--sp-bg-width);
        padding-top: spPx(70px);
    }

    .cultureIllustration {
        width: spPx(307px);
        margin: spPx(30px) auto spPx(10px) auto;
    }

    .cultureWrapper {
        width: fit-content;
        padding: 0;
        flex-wrap: nowrap;
        column-gap: 0;
        transition: transform 0.5s 0s ease;
    }

    .cultureBox {
        width: spPx(340px);
        height: spPx(340px);
        padding: spPx(40px) spPx(50px) 0 spPx(50px);
    }

    .cultureTitle {
        font-size: 3.125rem;
        margin: 0 0 spPx(32px) 0;
    }

    .cultureDetail {
        font-size: 2rem;
    }

    .cultureInner {
        max-width: calc(var(--sp-content-width) + var(--sp-content-padding)*2);
        padding: 30px var(--sp-content-padding) 15px var(--sp-content-padding);
        margin: 0 auto;
    }

    .cultureInner h2 {
        font-size: 4rem;
        margin: 0 0 15px 0;
    }

    .cultureInner h3 {
        font-size: 2rem;
        margin: 0 0 40px 0;
    }

    .cultureText {
        font-size: 2.1rem;
        text-align: left;
        margin-bottom: spPx(35px);
    }

    .cultureScroll {
        overflow: hidden;
        width: spPx(330px);
        margin: 0 auto;
        position: relative;
    }

    .scrollButtons {
        position: relative;
        width: 100%;
    }

    .leftScroll {
        background-image: url(../../public/img/sp/scroll_button.svg);
        background-size: contain;
        position: absolute;
        top: spPx(150px);
        left: spPx(-10px);
        width: spPx(40px);
        height: spPx(40px);
        transform: scale(-1, 1);
    }

    .rightScroll {
        background-image: url(../../public/img/sp/scroll_button.svg);
        background-size: contain;
        position: absolute;
        top: spPx(150px);
        right: spPx(-10px);
        width: spPx(40px);
        height: spPx(40px);
    }

    .cultureBgBottom {
        max-width: var(--sp-bg-width);
        height: spPx(50px);
        background-image: url(../../public/img/sp/culture_bg_bottom.png);
    }
}